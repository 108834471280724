import { combineReducers } from "redux";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import blogReducer from "./blogReducer";
import communityReducer from "./communityReducer";
import programReducer from "./programReducer";
import productReducer from "./productReducer";
import journalReducer from "./journalReducer";
import commonReducer from "./commonReducer";

const root = combineReducers({
    authReducer,
    profileReducer,
    blogReducer,
    communityReducer,
    programReducer,
    productReducer,
    journalReducer,
    commonReducer
})
export default root;