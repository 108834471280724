import { GET_ALL_BLOGS,GET_SINGLE_BLOG, BLOG_COMMENTS, SHOW_BLOG_COMMENTS, DEL_BLOG_COMMENTS } from "../actions/types";

const initialState = {
    blog_data:null,
    blog_datail:null,
    blog_comments:null,
    show_blog_comments:null,
    del_blog_comments:null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_BLOGS:
            return {
                ...state,
                blog_data: payload
            };
        case GET_SINGLE_BLOG:
            return {
                ...state,
                blog_datail: payload
            };
        case BLOG_COMMENTS:
            return {
                ...state,
                blog_comments: payload
            };
        case SHOW_BLOG_COMMENTS:
            return {
                ...state,
                show_blog_comments: payload
            };
        case DEL_BLOG_COMMENTS:
            return {
                ...state,
                del_blog_comments: payload
            };

        default:
            return state
    }
}