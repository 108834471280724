import { ADD_POST, GET_ALL_COMMUNITY, GET_ALL_COMMENTS, POST_COMMENTS, DEL_COMMENTS, DEL_POST, UPDATE_POST, LIKE_POST } from "../actions/types";

const initialState = {
    add_post:null,
    community_data:null,
    comments_data:null,
    add_comments:null,
    del_post:null,
    update_post:null,
    like_post:null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_POST:
            return {
                ...state,
                add_post: payload
            };

        case GET_ALL_COMMUNITY:
            return {
                ...state,
                community_data: payload
            };

        case GET_ALL_COMMENTS:
            return {
                ...state,
                comments_data: payload
            };

        case POST_COMMENTS:
            return {
                ...state,
                add_comments: payload
            };
            
        case DEL_COMMENTS:
            return {
                ...state,
                comments_data: payload
            };

        case DEL_POST:
            return {
                ...state,
                del_post: payload
            };

        case UPDATE_POST:
            return {
                ...state,
                update_post: payload
            };

        case LIKE_POST:
            return {
                ...state,
                like_post: payload
            };

        default:
            return state
    }
}