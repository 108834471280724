import { GET_ALL_CLASS, ADD_BJJ_CLASS, ADD_CUSTOM_CLASS, DELETE_BJJ_CLASS, DELETE_CUSTOM_CLASS, GET_JOURNAL_LIST, ADD_JOURNAL_LIST, DELETE_JOURNAL_LIST, PREVIOUS_JOURNAL_LIST, GET_JOURNAL_DOTS } from "../actions/types";

const initialState = {
    get_all_class: null,
    add_bjj_class: null,
    add_custom_class: null,
    get_jornal_dots: null,
    delete_bjj_class: null,
    delete_custom_class: null,
    get_journal_list: null,
    add_journal_list: null,
    delete_journal_list: null,
    prev_journal_list: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_CLASS:
            return {
                ...state,
                get_all_class: payload
            };

        case ADD_BJJ_CLASS:
            return {
                ...state,
                add_bjj_class: payload
            };

        case GET_JOURNAL_DOTS:
            return {
                ...state,
                get_jornal_dots: payload
            };

        case ADD_CUSTOM_CLASS:
            return {
                ...state,
                add_custom_class: payload
            };

        case DELETE_BJJ_CLASS:
            return {
                ...state,
                delete_bjj_class: payload
            };

        case DELETE_CUSTOM_CLASS:
            return {
                ...state,
                delete_custom_class: payload
            };

        case GET_JOURNAL_LIST:
            return {
                ...state,
                get_journal_list: payload
            };

        case ADD_JOURNAL_LIST:
            return {
                ...state,
                add_journal_list: payload
            };

        case DELETE_JOURNAL_LIST:
            return {
                ...state,
                delete_journal_list: payload
            };

        case PREVIOUS_JOURNAL_LIST:
            return {
                ...state,
                prev_journal_list: payload
            };

        default:
            return state
    }
}