import { LOGIN_SUCCESS, REGISTER_SUCCESS, VERIFY_OTP, RESEND_OTP, FORGOT_PASSWORD, RESET_PASSWORD, CHANGE_PASSWORD, CONTACT_MESSAGE, GET_STARTED_VIDEOS, GET_FAQ, GET_NOTIFICATION, READ_NOTIFICATION, UPDATE_SUBSCRIBE, ADD_CARD_DETAIL, BUY_SUBSCRIPTION, GET_ACADEMY_STUDENT, CHECK_SUBSCRIPTION_BY_EMAIL, VERIFY_SUBSCRIPTION_OTP, DELETE_ACADEMY_STUDENT, ADD_CARD_TRIAL, ADD_ACADEMY_ENQUIRY, CREATE_FEEDBACK_TICKET, CANCEL_SUBSCRIPTION } from "../actions/types";

const initialState = {
    token: '',
    isLoggedIn: false,
    login_data: null,
    register_data: null,
    verify_data: null,
    resend_data: null,
    forgot_data: null,
    reset_data: null,
    change_pass: null,
    // forgot_screen: null,
    // verify_screen: null,
    contact_msg: null,
    get_started_videos: null,
    get_faq: null,
    get_notifications: null,
    read_notifications: null,
    add_card_detail: null,
    buy_subscription: null,
    update_subscriber: null,
    get_academy_student: null,
    check_subscription_by_email: null,
    verify_subscription_otp: null,
    add_card_trial: null,
    add_academy_inquires: null,
    create_feedback_ticket: null,
    cancel_trial_subs: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoggedIn: true,
                login_data: payload
            };

        case REGISTER_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoggedIn: true,
                type: 'register',
                register_data: payload
            };

        case VERIFY_OTP:
            return {
                ...state,
                ...payload,
                isLoggedIn: true,
                type: 'verify',
                verify_data: payload
            };

        case RESEND_OTP:
            return {
                ...state,
                ...payload,
                isLoggedIn: true,
                type: 'resendcode',
                resend_data: payload
            };

        case FORGOT_PASSWORD:
            return {
                ...state,
                ...payload,
                isLoggedIn: true,
                type: 'forgot',
                forgot_data: payload
            };

        case RESET_PASSWORD:
            return {
                ...state,
                ...payload,
                isLoggedIn: true,
                type: 'reset',
                reset_data: payload
            };

        case CHANGE_PASSWORD:
            return {
                ...state,
                ...payload,
                change_pass: payload
            };

        case CONTACT_MESSAGE:
            return {
                ...state,
                contact_msg: payload
            };

        case GET_STARTED_VIDEOS:
            return {
                ...state,
                get_started_videos: payload
            };

        case GET_FAQ:
            return {
                ...state,
                get_faq: payload
            };

        case GET_NOTIFICATION:
            return {
                ...state,
                get_notifications: payload
            };

        case READ_NOTIFICATION:
            return {
                ...state,
                read_notifications: payload
            };

        case ADD_CARD_DETAIL:
            return {
                ...state,
                add_card_detail: payload
            };

        case BUY_SUBSCRIPTION:
            return {
                ...state,
                buy_subscription: payload
            };

        case UPDATE_SUBSCRIBE:
            return {
                ...state,
                update_subscriber: payload
            };

        case GET_ACADEMY_STUDENT:
            return {
                ...state,
                get_academy_student: payload
            };

        case DELETE_ACADEMY_STUDENT:
            return {
                ...state,
                delete_academy_student: payload
            };

        case CHECK_SUBSCRIPTION_BY_EMAIL:
            return {
                ...state,
                check_subscription_by_email: payload
            };

        case VERIFY_SUBSCRIPTION_OTP:
            return {
                ...state,
                verify_subscription_otp: payload
            };

        case ADD_CARD_TRIAL:
            return {
                ...state,
                add_card_trial: payload
            };

        case ADD_ACADEMY_ENQUIRY:
            return {
                ...state,
                add_academy_inquires: payload
            };

        case CREATE_FEEDBACK_TICKET:
            return {
                ...state,
                create_feedback_ticket: payload
            };

        case CANCEL_SUBSCRIPTION:
            return {
                ...state,
                cancel_trial_subs: payload
            };

        default:
            return state
    }
}