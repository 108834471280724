import { GET_ALL_PRODUCTS, PRODUCTS_DETAILS, PRODUCT_CATEGORY, CATEGORY_DATA, REVIEWS_DATA, ADD_CART, GET_CART_DATA, DELETE_CART, UPDATE_CART, ALL_ADDRESS, ADD_ADDRESS, DELETE_ADDRESS, UPDATE_ADDRESS, ORDER_PLACE, UPDATE_PAYMENT, GET_ORDERS_DETAILS, REORDER_PRODUCT } from "../actions/types";

const initialState = {
    all_products: null,
    product_details: null,
    product_category: null,
    category_data: null,
    reviews_data: null,
    add_cart: null,
    get_cart_data: null,
    delete_cart: null,
    update_cart: null,
    all_address: null,
    add_address: null,
    delete_address: null,
    update_address: null,
    place_order: null,
    update_payment: null,
    get_my_orders: null,
    reorder_product: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_PRODUCTS:
            return {
                ...state,
                all_products: payload
            };

        case PRODUCTS_DETAILS:
            return {
                ...state,
                product_details: payload
            };

        case PRODUCT_CATEGORY:
            return {
                ...state,
                product_category: payload
            };

        case CATEGORY_DATA:
            return {
                ...state,
                category_data: payload
            };

        case REVIEWS_DATA:
            return {
                ...state,
                reviews_data: payload
            };

        case ADD_CART:
            return {
                ...state,
                add_cart: payload
            };

        case GET_CART_DATA:
            return {
                ...state,
                get_cart_data: payload
            };

        case DELETE_CART:
            return {
                ...state,
                delete_cart: payload
            };

        case UPDATE_CART:
            return {
                ...state,
                update_cart: payload
            };

        case ALL_ADDRESS:
            return {
                ...state,
                all_address: payload
            };

        case ADD_ADDRESS:
            return {
                ...state,
                add_address: payload
            };

        case DELETE_ADDRESS:
            return {
                ...state,
                delete_address: payload
            };

        case UPDATE_ADDRESS:
            return {
                ...state,
                update_address: payload
            };

        case ORDER_PLACE:
            return {
                ...state,
                place_order: payload
            };

        case UPDATE_PAYMENT:
            return {
                ...state,
                update_payment: payload
            };

        case GET_ORDERS_DETAILS:
            return {
                ...state,
                get_my_orders: payload
            };

        case REORDER_PRODUCT:
            return {
                ...state,
                reorder_product: payload
            };

        default:
            return state
    }
}