import { applyMiddleware, createStore, compose } from "redux";
import { thunk } from 'redux-thunk'; // Updated import
import root from './reducers/allReducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';// defaults to localStorage

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];

// Set up configuration for persistence
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authReducer', 'profileReducer', 'commonReducer', 'programReducer']
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, root);

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)));

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };