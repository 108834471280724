import React from 'react';
import maintenanceImg from '../../assets/maintain.png';
import logo from '../../assets/logomain.png';

const Maintenance = () => {
    return (
        <>
            <div className="maintenance text-center">
                <div className="logo text-start">
                    <img src={logo} width={"10%"} />
                </div>
                <img src={maintenanceImg} width={"35%"} />
                <h1 className="small_font">We are coming soon!</h1>
                <h1 className="big_font text-danger">The website under maintenance!</h1>
            </div>
        </>
    )
}

export default Maintenance;