import React from 'react';
import bigfist from '../../assets/bigfist.png';
import logo from '../../assets/logomain.png';
import './welcome.css';

const Welcome = () => {
  return (
    <>
    <div className="welcome_fist text-center">
       <div className="big_fist">
        <img src={"https://d1uxchv5kl1noj.cloudfront.net/bigfist.png"} alt="bigfistpic" className='big_fist_img'/>
       </div>
       <div className="bullet_logo">
        <img src={"https://d1uxchv5kl1noj.cloudfront.net/logomain.png"} alt="logopic" className='welcome_logo_width'/>
       </div>
    </div>
    </>
  )
}

export default Welcome