import { PODCAST_DATA, STORE_DATA } from "../actions/types";

const initialState = {
    common_data: null,
    podcast_data: null
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case STORE_DATA:
            return {
                ...state,
                common_data: payload,
            };
        case PODCAST_DATA:
            return {
                ...state,
                podcast_data: payload,
            };
        default:
            return state
    }
}