import React, { lazy, Suspense, useState } from 'react';
import Welcome from './components/welcomescreen/Welcome';
import Maintenance from './components/maintenance/Maintenance';
import { HelmetProvider } from 'react-helmet-async';
const Routes = lazy(() => import("./routes/routes"));

const App = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  return (
    <>
      <HelmetProvider>
        {maintenanceMode ? <Maintenance /> :
          <Suspense
            fallback={
              <Welcome />
            }
          >
            <Routes />
          </Suspense>
        }
      </HelmetProvider>
    </>
  )
}

export default App;
