import { ADD_USER_DETAIL, PROFILE_DATA, UPDATE_PROFILE } from "../actions/types";

const initialState = {
    about: null,
    privacy_policy: null,
    terms: null,
    faq: null,
    contact: null,
    add_user_detail: null,
    user_data: null,
    update_data:null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_DATA:
            return {
                ...state,
                user_data: payload?.status ? payload?.data : null
            };

        case ADD_USER_DETAIL:
            return {
                ...state,
                add_user_detail: payload
            };

        case UPDATE_PROFILE:
            return {
                ...state,
                update_data: payload?.status ? payload?.data : null
            };

        default:
            return state
    }
}